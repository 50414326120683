import { unref } from "vue";

import { PLUGIN_EVENTS } from "@/constants";
import Sentry from "@/plugins/sentry";
import FunnelService from "@/api/funnelService";
import { ElMessageBox } from "element-plus/dist/index.full";
import { MERCHANT, PURCHASE } from "@/store/namespaces";

import { useStore } from "@/composables/store";

import { useI18n as useVueI18n } from "vue-i18n";

import { Logger } from "../utils/logger";

const initListener = () => {
  const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  const eventer = window[eventMethod];
  const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
  return { eventer, messageEvent };
};

export function postMessageParentFn({ rawSDKMobile, rawIframeId }) {
  return function (body) {
    /* global webkit */
    if (rawSDKMobile) {
      Logger.info({ id: "postMessageParent", body, rawSDKMobile });

      const ua = navigator.userAgent.toLowerCase();
      const isAndroid = ua.indexOf("android") > -1;

      if (isAndroid) {
        webkit.postMessage(JSON.stringify(body));
      } else {
        webkit.messageHandlers.callbackHandler.postMessage(body);
      }
    } else if (rawIframeId) {
      Logger.info({ id: "postMessageParent", body, rawIframeId });
      const payload = JSON.parse(JSON.stringify({ id: rawIframeId, ...body }));
      window.parent.postMessage(payload, "*");
    }
  };
}

export const usePlugin = () => {
  const { useGetters, useActions } = useStore();
  const { t: translate } = useVueI18n({ useScope: "global" });

  /* Computed. */
  const { started, rawSDKMobile, rawIframeId } = useGetters([
    "started",
    "rawSDKMobile",
    "rawIframeId",
  ]);
  const { webSessionId } = useGetters(MERCHANT, ["webSessionId"]);
  const { purchase } = useGetters(PURCHASE, ["purchase"]);

  /* Actions. */
  const { appSetStarted, sendStarted } = useActions(["appSetStarted", "sendStarted"]);

  /* Methods. */
  const postMessageParent = (eventName, payload, options) => {
    postMessageParentFn({
      rawSDKMobile: rawSDKMobile.value,
      rawIframeId: rawIframeId.value,
    })({
      name: eventName,
      payload,
      options,
    });
  };

  const emitSetBack = (type, debug) => {
    postMessageParent(PLUGIN_EVENTS.PLEDG_SETBACK, {
      type,
      debug,
    });
  };

  const listenExternalCheckoutValidation = (handleSubmitCb) => {
    const { eventer, messageEvent } = initListener();
    eventer(
      messageEvent,
      async (e) => {
        const {
          data: { name: eventName },
        } = e;
        switch (eventName) {
          case PLUGIN_EVENTS.PLEDG_EXTERNAL_CHECKOUT_VALIDATION:
            if (handleSubmitCb) {
              handleSubmitCb();
            }
            break;
          default:
            break;
        }
      },
      false
    );
  };

  const onMessageCb = async (e) => {
    const purchaseValue = unref(purchase);
    const {
      data: { name: eventName, payload = {} },
    } = e;
    switch (eventName) {
      /**
       * PLEDG_START means that the user clicked on the Pledg button.
       * We check the value of started in order to take into
       * account at most one PLEDG_START event. Indeed, if the client
       * clicks several times, we receive several times the
       * PLEDG_START event.
       */
      case PLUGIN_EVENTS.PLEDG_START:
        if (purchaseValue) {
          if (!started.value) {
            await appSetStarted();
            await sendStarted();
            await FunnelService.updateParams(purchaseValue.uid, {
              ...payload,
              webSessionId: webSessionId.value,
            });
          }
          /**
           * Stop issuing the PLEDG_START event from the plugin
           * once the 'started' flag is set to true
           */
          postMessageParent(PLUGIN_EVENTS.PLEDG_START_ACK);
        }
        break;
      /**
       * PLEDG_ASK_TO_CLOSE means that the user clicked on the Pledg close button
       */
      case PLUGIN_EVENTS.PLEDG_ASK_TO_CLOSE:
        postMessageParent(PLUGIN_EVENTS.PLEDG_WAIT);
        postMessageParent(PLUGIN_EVENTS.PLEDG_SCROLL_TO_TOP);

        // Confirm before closing Pledg iframe
        ElMessageBox.confirm(translate("closingWarning"), {
          center: true,
          confirmButtonText: translate("yes"),
          cancelButtonText: translate("no"),
          buttonSize: "small",
        })
          .then(() => {
            postMessageParent(PLUGIN_EVENTS.PLEDG_CANCEL);
          })
          .catch(() => {
            postMessageParent(PLUGIN_EVENTS.PLEDG_CANCEL_CLOSE);
          });
        break;
      case PLUGIN_EVENTS.PLEDG_BUILD_PARAMS_ERROR:
        {
          const { options, error } = payload;
          Sentry.error("pledg_build_params_error", {
            ...error,
            ...options,
          });
        }
        break;
      default:
        break;
    }
  };

  const onMessage = async () => {
    const { eventer, messageEvent } = initListener();
    eventer(messageEvent, onMessageCb, false);
  };

  return {
    postMessageParent,
    emitSetBack,
    listenExternalCheckoutValidation,
    onMessage,
    onMessageCb,
  };
};

export default usePlugin;
