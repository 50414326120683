import config from "@/config";
import Sentry from "@/plugins/sentry";
import errorParser from "@/helpers/errorParser";
import { TransactionTypes, FRONT_URL_PARAMS, PLUGIN_EVENTS } from "@/constants";

import PurchaseService from "@/api/purchaseService";
import OrderService from "@/api/orderService";

import { useStore } from "@/composables/store";
import { usePlugin } from "@/composables/plugin";

import browserRedirectToUrl from "@/helpers/browserRedirectToUrl";
import runWithDelay from "@/helpers/runWithDelay";
import AppContext from "@/AppContext";
import { Logger } from "../utils/logger";

const { REDIRECT_DELAY_IN_MS } = config;

const transaction_service = {
  [TransactionTypes.PURCHASE]: PurchaseService,
  [TransactionTypes.ORDER]: OrderService,
};

const getTransactionService = () => {
  return transaction_service[AppContext.transactionType];
};

const getCancelSignature = async (targetUid, error) => {
  if (!targetUid) return error;

  try {
    const { data } = await getTransactionService().cancelSignature(targetUid, error);
    return data;
  } catch (apiResponseError) {
    Sentry.error("close-funnel-window-cancel-signature", {
      cancelError: JSON.stringify(error),
      ...errorParser(apiResponseError),
    });
    return undefined;
  }
};

const computeCancelUrl = async ({ targetUid, error, rawCancelUrl, rawSignature }) => {
  const url = new URL(rawCancelUrl);

  const queryParamValue = rawSignature
    ? await getCancelSignature(targetUid, error)
    : error;

  url.searchParams.append(
    FRONT_URL_PARAMS.PLEDG_ERROR,
    JSON.stringify(queryParamValue)
  );
  browserRedirectToUrl(url);
};

export const useCloseFunnelWindow = () => {
  const { useGetters } = useStore();
  const { postMessageParent } = usePlugin();

  /* Computed. */
  const { rawIframeId, rawPurchaseUid, rawOrderUid, rawCancelUrl, rawSignature } =
    useGetters([
      "rawIframeId",
      "rawPurchaseUid",
      "rawOrderUid",
      "rawCancelUrl",
      "rawSignature",
    ]);

  const closeFunnelWindow = async (
    funnelError,
    redirectDelayInMs = REDIRECT_DELAY_IN_MS
  ) => {
    Logger.info({ id: "close-funnel-window", funnelError });

    const error = funnelError.merchantError;
    if (rawIframeId.value) {
      await runWithDelay(
        redirectDelayInMs,
        postMessageParent,
        PLUGIN_EVENTS.PLEDG_ERROR,
        {
          error,
          reload: false,
        }
      );
    } else if (rawCancelUrl.value) {
      /* In direct URL call, we need to wait a bit, so that the client
          can read the error.
          For instance, for the DEFERRED payment, it can be reserved to French residents.
        */
      await runWithDelay(redirectDelayInMs, computeCancelUrl, {
        targetUid: rawOrderUid.value || rawPurchaseUid.value,
        error,
        rawCancelUrl: rawCancelUrl.value,
        rawSignature: rawSignature.value,
      });
    }
  };

  return {
    closeFunnelWindow,
  };
};

export default useCloseFunnelWindow;
