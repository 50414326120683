import { useRoute } from "vue-router";
import { useI18n as useVueI18n } from "vue-i18n";
import { Logger } from "@/utils/logger";
import Sentry from "@/plugins/sentry";
import { SHARE, PURCHASE, ERROR, MERCHANT } from "@/store/namespaces";
import FunnelErrorModel from "@/models/FunnelErrorModel";
import { useStore } from "@/composables/store";

const DEFAULT_ERROR_OPTIONS = {
  metadata: {},
  abortFunnel: true,
  displayedError: undefined,
  reportError: true,
};

export const useError = () => {
  const { useGetters, useActions } = useStore();
  const { name: routeName, fullPath: routeFullPath } = useRoute();
  const { t } = useVueI18n({ useScope: "global" });

  const { shareUid } = useGetters(SHARE, ["shareUid"]);
  const {
    rawMerchantUid,
    rawPurchaseUid,
    rawShareUid,
    rawEmail,
    rawAmountCents,
    rawReference,
    rawIsSeminal,
    rawIsPurchaseLink,
    rawIframeId,
  } = useGetters([
    "rawMerchantUid",
    "rawPurchaseUid",
    "rawShareUid",
    "rawEmail",
    "rawAmountCents",
    "rawReference",
    "rawIsSeminal",
    "rawIsPurchaseLink",
    "rawIframeId",
  ]);
  const { merchantName, infoEmail } = useGetters(MERCHANT, [
    "merchantName",
    "infoEmail",
  ]);
  const { purchaseUid } = useGetters(PURCHASE, ["purchaseUid"]);

  const { setFunnelErrorAction } = useActions(ERROR, ["setFunnelErrorAction"]);

  const defaultDisplayedError = () => {
    let content = t("ineligibilityErrorContent", { infoEmail: infoEmail.value });
    if (merchantName.value) {
      content = t("ineligibilityErrorContentWithMerchant", {
        merchantName: merchantName.value,
        infoEmail: infoEmail.value,
      });
    }
    let subtitle = t("ineligibilityPaymentMethod");
    // Show a different message if redirecting to payment methods page
    if (rawIsSeminal.value && !(rawIsPurchaseLink.value || rawIframeId.value)) {
      if (merchantName.value) {
        subtitle = t("ineligibilityPaymentMethodRedirectWithMerchant", {
          merchantName: merchantName.value,
        });
      } else {
        subtitle = t("ineligibilityPaymentMethodRedirect");
      }
    }
    return {
      title: t("retry_error"),
      subtitle,
      content,
    };
  };

  const handleError = (id, error_type, inputMessage, inputOptions = {}) => {
    const options = { ...DEFAULT_ERROR_OPTIONS, ...inputOptions };
    const message = inputMessage || t("genericError");

    const error = {
      error_type,
      message,
      email: rawEmail.value,
      amount_cents: rawAmountCents.value,
      reference: rawReference.value,
      merchant_uid: rawMerchantUid.value,
      purchase_uid: rawPurchaseUid.value || purchaseUid.value,
      share_uid: rawShareUid.value || shareUid.value,
      current_url: routeFullPath,
      user_agent: navigator.userAgent,
      metadata: options.metadata,
    };

    Logger.info({
      id,
      route_name: routeName,
      error,
      options,
    });

    if (options.reportError) {
      Sentry.warn(
        id,
        {
          ...error,
        },
        options
      );
    }
    if (options.abortFunnel) {
      const funnelError = new FunnelErrorModel({
        type: error_type,
        message,
        displayedError: options.displayedError || defaultDisplayedError(),
      });
      setFunnelErrorAction(funnelError);
    }
  };

  const handleInegibilityError = (id, error_type, inputOptions = {}) => {
    return handleError(id, error_type, t("retry_error"), {
      ...inputOptions,
      ...defaultDisplayedError(),
    });
  };

  const handleErrorDelayed = (id, errorType, message, options, delay = 4000) => {
    setTimeout(() => {
      handleError(id, errorType, message, options);
    }, delay);
  };

  return { handleError, handleInegibilityError, handleErrorDelayed };
};

export default useError;
